import React from "react";
import styled from "styled-components";
import { DateTimePicker } from "@bilgorajskim/material-ui-pickers";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";

export const StyledTextField = styled(TextField)`
  background: ${(props) => props.theme.color.white.regular} !important;
  border-radius: 4px;

  input {
    color: #222 !important;
    padding: 10px 14px;
    padding-right: 0;
    font-size: 16px;
    line-height: 14px;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & span {
    font-size: 14px !important;
    line-height: 14px;
  }
  display: block;
  margin-top: 0em;
  margin-bottom: -0.25em;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 4px 4px;
  margin-left: 5px;
  margin-right: 5px;
  & .MuiIconButton-label {
    position: relative;
  }
  & .MuiIconButton-label::before {
    content: " ";
    display: block;
    z-index: 1;
    position: absolute;
    background: white !important;
    top: 3.25px;
    left: 3.25px;
    bottom: 3.25px;
    right: 3.25px;
    border-radius: 2px;
  }
  & svg {
    z-index: 10;
  }
`;

export const StyledSelect = styled(Select)`
  background: ${(props) => props.theme.color.white.regular} !important;
  border-radius: 4px;

  select {
    padding: 10px 14px;
    padding-right: 40px;
    font-size: 16px;
    line-height: 16px;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 7px;
  }
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  background: ${(props) => props.theme.color.white.regular} !important;
  border-radius: 4px;

  input {
    color: #222 !important;
    padding: 10px 14px;
    padding-right: 0;
    font-size: 16px;
    line-height: 14px;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  > div::before {
    display: none;
  }
  > div::after {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.white.dark};
  border-radius: 4px;
  box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
  ${(props) => props.theme.font_size.small};
  color: #222;
`;
export const InnerWrapper = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media (min-width: 330px) {
    max-width: 400px;
  }
  @media (min-width: 500px) {
    max-width: 450px;
  }
  @media (min-width: 767px) {
    max-width: 550px;
  }
  @media (min-width: 850px) {
    max-width: initial;
    padding: 20px 20px;
  }
`;

export const Header = styled.strong`
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.regular};
  display: block;
  margin-top: -0.25rem;
`;

export const Label = styled.label`
  ${(props) => props.theme.font_size.small};
  text-transform: uppercase;
  margin-top: 0.25rem;
  display: block;
`;

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 5px 0;

  label {
    ${(props) => props.theme.font_size.small};
    text-transform: uppercase;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
`;

export const AdresHaczyk = styled.p`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.xsmall};
  margin-top: 0.5rem;
`;

export const Error = styled.div`
  color: red;
`;

export const Button = styled.button`
  background: ${(props) => props.theme.color.primary.regular};
  color: white;
  padding: 12px 15px;
  display: block;
  width: 100%;
  border-radius: 4px;
  margin-top: 15px;
  &:hover,
  &:focus,
  &:active {
    background: ${(props) => props.theme.color.primary.light};
  }
  &:focus {
    border-radius: 0;
    outline: solid 3px #222;
  }
`;
