import React, { useState, useCallback, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import DateTimeContext from "@common/DateTimeContext";
import { Calendar } from "styled-icons/boxicons-regular";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MapPin } from "styled-icons/boxicons-solid";
import { DateTime } from "luxon";
import {
  Wrapper,
  InnerWrapper,
  Header,
  TwoGrid,
  Error,
  Button,
  StyledDateTimePicker,
  StyledCheckbox,
  StyledSelect,
  StyledFormControlLabel,
  StyledTextField,
  Label,
  AdresHaczyk,
} from "./style";

function alignUp(unaligned, alignTo) {
  const aligned = Math.ceil(unaligned / alignTo) * alignTo;
  return aligned;
}

function alignDate(date) {
  return new Date(alignUp(date.getTime(), 1000 * 60 * 15));
}

const CalendarInput = ({ ...props }) => {
  return (
    <TextField
      {...props}
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Calendar />
          </InputAdornment>
        ),
      }}
    />
  );
};

const CalendarInputOdbior = ({ ...props }) => {
  return <CalendarInput {...props} />;
};

const CalendarInputZwrot = ({ ...props }) => {
  return <CalendarInput {...props} />;
};

const ReservationBox = ({ car, segment, state, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            offices {
              name
              workingHoursMonFri {
                start
                end
              }
              workingHoursSaturday {
                start
                end
              }
              deliveryFee
              returnFee
              deliveryFeeOutsideWorkingHours
              returnFeeOutsideWorkingHours
            }
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    `
  );
  const [odbior, setOdbior] = useState(
    state && state.odbior
      ? state.odbior
      : data.site.siteMetadata.offices[0].name
  );
  const [zwrot, setZwrot] = useState(
    state && state.zwrot ? state.zwrot : data.site.siteMetadata.offices[0].name
  );
  const [odbiorAddress, setOdbiorAddress] = useState(
    state && state.odbiorAddress ? state.odbiorAddress : ""
  );
  const [zwrotAddress, setZwrotAddress] = useState(
    state && state.zwrotAddress ? state.zwrotAddress : ""
  );
  const [samePlace, setSamePlace] = useState(
    state && state.odbiorAddress && state.zwrotAddress ? state.samePlace : true
  );
  const [odbiorDate, handleOdbiorDate] = useState(
    state && state.odbiorDate
      ? new Date(state.odbiorDate)
      : alignDate(new Date())
  );
  const [zwrotDate, handleZwrotDate] = useState(
    state && state.zwrotDate
      ? new Date(state.zwrotDate)
      : alignDate(new Date(Date.now() + 1000 * 60 * 60 * 24))
  );
  const [error, setError] = useState(null);
  const submitForm = useCallback(() => {
    const state = {
      odbior,
      zwrot,
      odbiorOffice: data.site.siteMetadata.offices.find(
        (o) => o.name === odbior
      ),
      zwrotOffice: data.site.siteMetadata.offices.find((o) => o.name === zwrot),
      odbiorDate: odbiorDate.getTime(),
      zwrotDate: zwrotDate.getTime(),
      samePlace,
      odbiorAddress,
      zwrotAddress,
    };

    const fromDate = DateTime.fromMillis(state.odbiorDate);
    const toDate = DateTime.fromMillis(state.zwrotDate);
    if (toDate.diff(fromDate).as("hours") < 1) {
      setError(`Data zwrotu jest niepoprawna`);
      return;
    }
    if (state.odbior.includes("adres") && !odbiorAddress.trim()) {
      setError(
        `Proszę wpisać adres odbioru ${odbior === zwrot ? "i zwrotu" : ""}`
      );
      return;
    }
    if (state.zwrot.includes("adres") && !zwrotAddress.trim()) {
      setError("Proszę wpisać adres zwrotu");
      return;
    }
    if (car && segment) {
      state.car = car;
      state.segment = segment;
      navigate("/rezerwacja/ubezpieczenie", {
        state,
      });
    } else {
      navigate("/rezerwacja/samochod", {
        state,
      });
    }
  }, [
    odbior,
    zwrot,
    samePlace,
    odbiorDate,
    zwrotDate,
    odbiorAddress,
    zwrotAddress,
  ]);

  return (
    <Wrapper {...props}>
      <InnerWrapper>
        <Header>Zarezerwuj samochód</Header>
        <TwoGrid>
          <Label>
            Miejsce odbioru
            <br />
            <StyledSelect
              IconComponent={MapPin}
              native
              fullWidth
              variant="outlined"
              value={odbior}
              onChange={(ev) => {
                setOdbior(ev.target.value);
                if (samePlace) {
                  setZwrot(ev.target.value);
                } else {
                  setSamePlace(ev.target.value === zwrot);
                }
              }}
            >
              {data.site.siteMetadata.offices.map((p) => (
                <option key={p.name}>{p.name}</option>
              ))}
            </StyledSelect>
          </Label>
          <Label>
            Miejsce zwrotu
            <br />
            <StyledSelect
              IconComponent={MapPin}
              native
              fullWidth
              variant="outlined"
              value={zwrot}
              onChange={(ev) => {
                setZwrot(ev.target.value);
                setSamePlace(ev.target.value === odbior);
              }}
            >
              {data.site.siteMetadata.offices.map((p) => (
                <option key={p.name}>{p.name}</option>
              ))}
            </StyledSelect>
          </Label>
        </TwoGrid>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={samePlace}
              disabled={
                !odbior.includes("adres") &&
                !zwrot.includes("adres") &&
                odbior === zwrot
              }
              onChange={(ev) => {
                const checked = ev.target.checked;
                setSamePlace(checked);
                if (checked) {
                  setZwrot(odbior);
                }
              }}
              color="primary"
            />
          }
          label="miejsce zwrotu samochodu takie samo jak odbioru"
        />
        {odbior.includes("adres") ? (
          <>
            <Label>
              {samePlace ? "Adres odbioru i zwrotu" : "Adres odbioru"}
              <br />
              <StyledTextField
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={odbiorAddress}
                onChange={(ev) => {
                  setOdbiorAddress(ev.target.value);
                  if (samePlace) {
                    setZwrotAddress(ev.target.value);
                  }
                }}
              />
            </Label>
          </>
        ) : null}
        {zwrot.includes("adres") && !samePlace ? (
          <>
            <Label>
              Adres zwrotu
              <br />
              <StyledTextField
                fullWidth
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={zwrotAddress}
                onChange={(ev) => {
                  setZwrotAddress(ev.target.value);
                }}
              />
            </Label>
          </>
        ) : null}

        {odbior.includes("adres") || zwrot.includes("adres") ? (
          <AdresHaczyk>
            Zostanie doliczona opłata w wysokości 68 zł za podstawienie pod
            podany adres
            {odbior.includes("adres") && zwrot.includes("adres")
              ? ` oraz opłata w wysokości 68 zł za zwrot pod podanym adresem.`
              : "."}
          </AdresHaczyk>
        ) : null}

        <DateTimeContext>
          <TwoGrid>
            <Label>
              Data odbioru
              <br />
              <StyledDateTimePicker
                invalidDateMessage="Nieprawidłowy format daty"
                cancelLabel="Anuluj"
                clearLabel="Wyczyść"
                okLabel="Wybierz"
                todayLabel="Dziś"
                format="d MMM yyyy H:mm"
                ampm={false}
                hideTabs={false}
                minutesStep={15}
                DialogProps={{
                  transitionDuration: {
                    enter: 0,
                    exit: 0,
                  },
                }}
                disablePast
                // shouldDisableDate={d => d.weekday === 7}
                value={odbiorDate}
                onChange={(d) => handleOdbiorDate(alignDate(d.toJSDate()))}
                TextFieldComponent={CalendarInputOdbior}
              />
            </Label>

            <Label>
              Data zwrotu
              <br />
              <StyledDateTimePicker
                invalidDateMessage="Nieprawidłowy format daty"
                cancelLabel="Anuluj"
                clearLabel="Wyczyść"
                okLabel="Wybierz"
                todayLabel="Dziś"
                format="d MMM yyyy H:mm"
                ampm={false}
                hideTabs={false}
                minutesStep={15}
                DialogProps={{
                  transitionDuration: {
                    enter: 0,
                    exit: 0,
                  },
                }}
                disablePast
                // shouldDisableDate={d => d.weekday === 7}
                value={zwrotDate}
                onChange={(d) => handleZwrotDate(alignDate(d.toJSDate()))}
                TextFieldComponent={CalendarInputZwrot}
              />
            </Label>
          </TwoGrid>
        </DateTimeContext>
        {error ? <Error>{error}</Error> : null}
        <Button onClick={submitForm}>Dalej</Button>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ReservationBox;
